import React from 'react'

function Email() {
  return (
    <div className='Email'>
      <div className="klaviyo-form-RDqSQ3"></div>
    </div>
  )
}

export default Email
